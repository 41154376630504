import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useSnackbar } from '~/utils/withSnackbar';
import { useIntl } from 'react-intl';
import { useSelector } from '~/utils/redux';
import { useScreenReaderMode } from '~/utils/screenReaderMode/useScreenReaderMode';
import { VirtualizedMenuContext } from './VirtualizedMenuContext';
import { visualRegressionsMode } from '../../../../utils/visualRegressionsMode';

// Fetches and attaches data to sections.
// At first render this class fetches data for currently visible sections.
// When visible sections are loaded, it loads sections before and after visible sections for a smooth scrolling experience.
export const SectionsDataFetcher = ({ children, sections, rangeOfVisibleSections }) => {
  const orderingEventId = useSelector(state => state.consumer.orderingEventId) || -1;
  const [sectionsWithData, setSectionsWithData] = useState(sections);

  // Function to update a particular section with new properties
  // sectionsWithData[0].loading = true;  ===translates-to===>  updateSection(0, { loading: true });
  const updateSection = useCallback((index, updatedValues) => {
    setSectionsWithData((prevSectionsWithData) => {
      const newSectionsWithData = [...prevSectionsWithData];
      newSectionsWithData[index] = { ...newSectionsWithData[index], ...updatedValues };
      return newSectionsWithData;
    });
  }, []);

  const { showSnackbarError } = useSnackbar();
  const intl = useIntl();

  // Function to load data for a specific section
  const loadSection = useCallback((index) => {
    // Only load the section if it hasn't been loaded and isn't currently loading
    if (!sectionsWithData[index].data && !sectionsWithData[index].loading) {
      // Set the section as loading
      updateSection(index, { loading: true });
      // Fetch the data for the section and update the section with the fetched data
      sectionsWithData[index]
        .fetchData(sectionsWithData[index], orderingEventId)
        .then((data) => {
          updateSection(index, { data, loading: false });
        }).catch(() => {
          showSnackbarError(intl.formatMessage({ defaultMessage: 'Failed to load menu section. Please reload the page and try again.', id: 'consumer.menu_section.failed_to_load' }));
        });
    }
  }, [sectionsWithData, updateSection, showSnackbarError, intl, orderingEventId]);

  // On component render or on change of sections, sectionsWithData, updateSection or rangeOfVisibleSections
  // Load data for visible sections and the sections just before and after
  useEffect(() => {
    let areVisibleSectionsLoaded = true;
    for (let i = rangeOfVisibleSections.startIndex; i <= rangeOfVisibleSections.endIndex; i += 1) {
      // If data for a visible section is not loaded, set flag to false
      if (!sectionsWithData[i].data) {
        areVisibleSectionsLoaded = false;
      }
      // Load data for the section
      loadSection(i);
    }

    // If all visible sections are loaded, load the sections just before and after for smoother scrolling experience
    if (areVisibleSectionsLoaded) {
      const indexBeforeRange = rangeOfVisibleSections.startIndex - 1;
      if (indexBeforeRange >= 0) {
        loadSection(indexBeforeRange);
      }
      const indexAfterRange = rangeOfVisibleSections.endIndex + 1;
      if (indexAfterRange < sectionsWithData.length) {
        loadSection(indexAfterRange);
      }
    }
  }, [loadSection, sections, sectionsWithData, updateSection, rangeOfVisibleSections]);

  // In rare cases the sections prop may change. This would cause the sectionsWithData state to be out of sync.
  // To prevent this, we check if the sections prop has changed and if so, reset the sectionsWithData state.
  // To do it safely, we remove all function properties from the sections prop and the sectionsWithData state.
  useEffect(() => {
    const sectionsWithoutData = sectionsWithData.map((section) => {
      // |data| and |loading| are added to sectionsWithData in this class, so
      // they will always be different, so we remove them from the comparison
      const { data, loading, ...sectionWithoutData } = section;
      return sectionWithoutData;
    });

    if (JSON.stringify(sections) !== JSON.stringify(sectionsWithoutData)) {
      console.warn('[POPMENU] Sections changed, resetting SectionsDataFetcher. If seeing re-rendering issues, perhaps this is the cause.');
      setSectionsWithData(sections);
    }
  }, [sections, sectionsWithData]);

  return children({
    sectionsWithData,
  });
};

const style = {
  marginBottom: 50,
};

export const VirtualizedMenuSections = ({ sections, itemContent }) => {
  // The component is a bit too complex to support dynamic sections prop.
  // If you really want dynamic sections, tweak SectionsDataFetcher to support it.
  const { virtuosoRef, virtuosoOffset, onVirtuosoRendered } = useContext(VirtualizedMenuContext);
  const { screenReaderMode } = useScreenReaderMode();
  // 10000000 is an arbitrary big number to make sure that all sections are loaded.
  const increaseViewportBy = (visualRegressionsMode || screenReaderMode) ? 10000000 : 0;

  const [range, setRange] = useState({ endIndex: 0, startIndex: 0 });

  // Do not render menu sections if there is no data
  if (sections.length === 0) return null;

  return (
    <SectionsDataFetcher sections={sections} rangeOfVisibleSections={range}>
      {({ sectionsWithData }) => (
        <Virtuoso
          // The average menu section height exceeds the defaultItemHeight value specified below.
          // However, setting it larger than the average viewport height would be unnecessary.
          defaultItemHeight={896}
          useWindowScroll
          // Decrease viewport size to compensate for nav bar height.
          increaseViewportBy={{
            bottom: increaseViewportBy,
            top: -virtuosoOffset + increaseViewportBy,
          }}
          style={style}
          ref={virtuosoRef}
          data={sectionsWithData}
          totalCount={sections.length}
          itemsRendered={onVirtuosoRendered}
          rangeChanged={setRange}
          itemContent={itemContent}
          initialItemCount={1}
        />
      )}
    </SectionsDataFetcher>
  );
};
